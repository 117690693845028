import React from 'react';
import Container from 'react-bootstrap/Container';
import { Button } from "./common/Button";
import { WebHeading } from './common/WebHeading';
import our_Arrow from '../assets/images/Homepage/our_Arrow.svg';

const OurVision = () => {
  return (
    <>
      <section className='our_vision_sec'>
        <Container>
          <div className='our_vision_row'>
            <div className='our_arrow_block'>
              <div className='arrows'>
                <a href="https://defi.animalfarm.app">
                  <img src={our_Arrow} alt="our_Arrow" className="rotate" />
                </a>
              </div>
            </div>
            <div className='our_vision_text'>
              <div>
                <span>our vision</span>
                <WebHeading WebHeading="The Animal Farm" />
                <p>Our vision is to make traditional finance tools, typically only reserved for the super wealthy, available to anyone by using decentralized protocols which are not limited by the gatekeeping of centralized institutions. All of our products utilize trustless models that allow users to take full ownership of their personal finances. Lending and yield aggregating is the main focus of Animal Farm, but unlike other platforms Animal Farm is the only true decentralized ownership lending and yield aggregating platform.</p>
                <p>This means taxes and fees which would typically go to administrators instead go to you, the participants of the platform, through use of our innovative governance ownership structure which pays out dividends in both BUSD and BNB. This provides a unique opportunity for our users to profit as the super wealthy do, by accumulating ownership of assets and earning yield on them in the form of dividends.</p>
                <Button btnname="Launch App" href="https://defi.animalfarm.app" />
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  )
}

export default OurVision;
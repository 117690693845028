import React from 'react';
import Container from 'react-bootstrap/Container';
import LaptopBook_Pro from '../assets/images/Homepage/LaptopBook_Pro.png';
import 'react-html5video/dist/styles.css';

const VisitMarmarSec = () => {
  return (
    <>
      <section className='visit_marmar'>
        <Container>
          <div className='laptop_block_main'>
            <div className='laptop_block'>
              <img src={LaptopBook_Pro} alt='LaptopBook_Pro' />
              <div className='laptop_video'>
                <iframe
                  src='https://youtube.com/embed/3-I3BRntAfQ'
                  frameBorder='0'
                  allow='autoplay; encrypted-media'
                  allowFullScreen
                  title='video'
                  x-frame-options='ALLOWALL'
                  className='VideoBLock'
                />
              </div>
            </div>
          </div>
          <div className='visit_paragraph'>
            <p>
              All markets have demand cycles, and Animal Farm is the only platform to have dynamic emission rates based on the current demand for
              underlying assets, reducing the supply in times of low demand to allow for optimal price performance in all market conditions. On
              top of this, Animal Farm is the only yield aggregating lending protocol which allows users to secure profits in the form of a BUSD &
              BNB dividend, generated from both liquidity provider fees and low risk lending, without ever needing to sell their AFD (yield asset)
              or AFP (governance asset) tokens.
            </p>
            <p>
              When incentives are properly aligned, value is created. Animal Farm aligns the platform's incentives with the userbase by giving all
              users a path to ownership of the platform itself and a loyalty system that encourages users to build wealth like the super wealthy,
              by accumulating assets and securing profit in the form of dividends over selling native assets (AFD & AFP). These are some of the
              ways AFD & AFP are designed to be the highest performing yield generating assets ever created.
            </p>
          </div>
        </Container>
      </section>
    </>
  );
};

export default VisitMarmarSec;
